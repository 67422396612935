/**
 * Utilities specific to WSCPA
 */

/**
 * Dependencies
 */

import './pages/heroAlert';
import convertToLocalTime from '../../vendor/imarc/inkwell/resources/scripts/modules/datetimeHandler';

const timeElements = [...document.querySelectorAll('time')];

window.addEventListener('content-populated', () => {
    timeElements.forEach(convertToLocalTime);
});
